import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 865.000000 865.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,865.000000) scale(0.100000,-0.100000)">


<path d="M4585 7321 c-3 -12 -4 -25 -1 -30 4 -5 2 -21 -4 -35 -9 -26 -11 -26
-78 -20 -45 4 -72 3 -76 -4 -9 -15 6 -22 57 -23 25 -1 51 -5 56 -9 7 -4 0 -40
-20 -100 -43 -131 -42 -130 -92 -130 -60 0 -98 -40 -105 -108 -4 -44 -1 -52
25 -79 29 -30 83 -50 102 -39 5 3 13 0 18 -7 11 -13 66 -217 61 -223 -2 -2
-26 -5 -53 -7 -33 -3 -50 -9 -50 -18 0 -10 12 -13 45 -11 25 2 53 5 62 8 13 4
18 -3 24 -30 3 -20 4 -43 1 -51 -10 -23 21 -47 44 -35 25 14 24 44 -1 61 -11
8 -21 25 -22 39 -3 20 5 28 43 46 26 13 72 51 106 88 58 63 62 66 107 66 25 0
46 -4 46 -10 0 -15 48 -12 53 3 7 20 -12 47 -33 47 -11 0 -20 -4 -20 -10 0 -5
-20 -10 -45 -10 l-45 0 18 43 c12 29 17 69 17 132 0 79 -4 97 -29 149 -36 74
-85 129 -153 172 -57 37 -61 61 -16 91 33 22 28 57 -9 61 -19 2 -28 -2 -33
-17z m45 -17 c0 -9 -5 -14 -12 -12 -18 6 -21 28 -4 28 9 0 16 -7 16 -16z m16
-156 c125 -85 184 -249 138 -389 -9 -27 -20 -49 -24 -49 -5 0 -54 23 -109 51
-98 50 -101 52 -101 85 0 31 4 37 43 53 23 10 57 22 75 26 29 6 41 23 26 38
-4 3 -38 -10 -76 -29 l-70 -35 -28 32 -27 33 20 50 c10 28 22 56 26 61 4 6 15
31 25 58 10 26 21 47 26 47 4 0 29 -15 56 -32z m-11 -408 c43 -27 87 -53 98
-56 17 -5 17 -7 1 -32 -32 -50 -129 -122 -163 -122 -5 0 -25 50 -45 111 -35
107 -36 113 -19 130 27 27 42 23 128 -31z m277 -65 c0 -5 -5 -11 -11 -13 -6
-2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-312 -281 c0 -16 -16 -19
-25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z"/>
<path d="M3990 1944 c-6 -15 -9 -109 -8 -223 l3 -196 82 -3 c97 -4 136 11 171
61 29 43 30 119 2 164 -34 56 -86 72 -170 52 -12 -3 -16 12 -18 84 -3 85 -3
87 -28 87 -17 0 -27 -8 -34 -26z m175 -209 c34 -33 35 -112 3 -143 -27 -25
-69 -36 -97 -27 -19 6 -21 14 -21 90 0 69 3 86 18 94 30 18 72 12 97 -14z"/>
<path d="M2069 1725 c-17 -47 -36 -84 -42 -82 -5 1 -22 37 -36 78 -25 70 -28
74 -58 77 -18 2 -33 -1 -33 -5 0 -5 22 -61 49 -124 26 -62 51 -122 54 -131 11
-30 21 -20 56 57 19 41 37 75 41 75 4 0 22 -37 41 -81 l32 -82 20 39 c11 22
42 88 68 147 l49 108 -27 -3 c-24 -3 -32 -13 -58 -77 -17 -41 -36 -76 -41 -78
-5 -2 -22 35 -38 82 -15 47 -31 85 -36 85 -5 0 -23 -38 -41 -85z"/>
<path d="M2530 1800 c0 -17 -63 -160 -71 -160 -4 0 -20 36 -36 80 -27 78 -29
80 -62 80 -39 0 -42 15 32 -157 28 -68 54 -123 57 -123 4 0 21 34 40 75 18 42
37 74 42 73 5 -2 22 -35 37 -73 15 -38 32 -70 37 -72 7 -2 105 198 133 269 2
5 -10 8 -25 6 -25 -3 -32 -12 -59 -80 -16 -43 -34 -77 -39 -75 -6 2 -22 39
-36 82 -14 43 -32 81 -38 83 -7 2 -12 -1 -12 -8z"/>
<path d="M2934 1728 c-18 -45 -36 -83 -40 -85 -5 -2 -22 32 -39 75 -29 73 -32
77 -64 80 -25 3 -32 0 -28 -10 3 -7 26 -62 52 -123 26 -60 49 -118 52 -127 3
-10 8 -18 12 -18 4 0 24 36 45 81 20 45 39 79 42 76 3 -3 18 -38 34 -78 15
-40 32 -75 37 -76 6 -2 113 216 131 267 2 6 -8 10 -22 10 -20 0 -29 -7 -37
-27 -6 -16 -22 -54 -37 -87 l-26 -58 -32 88 c-17 48 -35 89 -40 91 -5 2 -23
-34 -40 -79z"/>
<path d="M4438 1786 c-46 -24 -48 -25 -48 -6 0 16 -7 20 -30 20 l-30 0 0 -135
0 -135 35 0 35 0 0 94 c0 87 2 96 22 110 12 9 34 16 48 16 48 0 54 -12 58
-119 l4 -101 29 0 29 0 0 108 c0 84 -4 113 -17 132 -9 14 -17 26 -17 27 -1 0
-17 4 -36 8 -27 5 -46 0 -82 -19z"/>
<path d="M4733 1794 c-96 -49 -98 -210 -4 -259 40 -20 116 -19 152 2 75 44 88
162 25 231 -28 30 -40 36 -87 39 -37 2 -64 -2 -86 -13z m115 -54 c39 -36 41
-112 5 -152 -22 -24 -74 -23 -101 2 -31 29 -31 114 0 147 27 28 67 30 96 3z"/>
<path d="M5156 1720 c-21 -49 -39 -90 -41 -90 -2 0 -18 38 -36 85 -31 78 -36
85 -61 85 -15 0 -28 -5 -28 -11 0 -7 26 -72 57 -146 l57 -134 42 88 42 88 11
-30 c36 -93 56 -135 63 -133 8 3 114 225 125 261 4 13 -1 17 -20 17 -21 0 -29
-10 -58 -80 -18 -44 -36 -80 -40 -80 -4 0 -20 38 -35 85 -16 47 -31 85 -35 85
-3 0 -23 -40 -43 -90z"/>
<path d="M5637 1788 c-39 -23 -77 -85 -77 -126 0 -34 24 -84 54 -112 21 -19
40 -25 90 -28 75 -5 106 8 106 44 0 21 -4 25 -17 19 -104 -42 -195 34 -149
123 17 33 71 58 106 48 11 -3 29 -9 40 -12 17 -4 20 -1 20 24 0 22 -6 31 -22
36 -48 12 -113 5 -151 -16z"/>
<path d="M5944 1796 c-71 -31 -98 -147 -51 -217 32 -47 62 -60 129 -56 65 4
100 28 124 86 19 44 17 67 -6 118 -33 74 -117 103 -196 69z m117 -62 c49 -53
21 -164 -42 -164 -44 0 -66 15 -79 55 -32 96 58 176 121 109z"/>
<path d="M6350 1799 c-13 -6 -32 -17 -41 -25 -16 -14 -18 -13 -24 5 -4 15 -15
21 -36 21 l-29 0 0 -135 0 -135 35 0 35 0 0 90 c0 102 15 130 70 130 41 0 50
-23 50 -129 l0 -91 35 0 35 0 0 94 c0 87 2 96 22 110 31 22 75 20 88 -3 5 -11
10 -60 10 -110 l0 -91 36 0 36 0 -3 115 c-4 103 -6 117 -27 137 -30 31 -90 30
-132 -1 -32 -23 -32 -23 -53 -3 -27 25 -74 34 -107 21z"/>
<path d="M3350 1791 c0 -5 -3 -16 -6 -25 -5 -13 3 -16 49 -16 31 0 58 -4 61
-8 3 -5 -24 -52 -59 -105 -36 -53 -65 -99 -65 -102 0 -3 53 -4 118 -3 113 3
117 4 120 26 3 21 0 22 -63 22 -80 0 -80 -6 5 119 33 50 60 93 60 96 0 3 -49
5 -110 5 -60 0 -110 -4 -110 -9z"/>
<path d="M3640 1686 c0 -99 3 -117 21 -140 26 -33 88 -36 137 -6 40 25 42 25
42 -1 0 -16 5 -20 28 -17 l27 3 3 138 3 138 -33 -3 -33 -3 -3 -97 c-3 -108
-10 -118 -74 -120 -48 -2 -58 22 -58 129 l0 93 -30 0 -30 0 0 -114z"/>
<path d="M3197 1584 c-25 -25 8 -70 43 -59 32 10 24 59 -10 63 -14 2 -29 0
-33 -4z"/>
<path d="M5421 1576 c-9 -11 -10 -20 -1 -35 19 -36 64 -20 58 20 -4 28 -39 37
-57 15z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
